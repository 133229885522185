import React, { useState } from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl/dist/react-intl';
import classNames from 'classnames';

import { types as sdkTypes } from '../../util/sdkLoader';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import { createSlug } from '../../util/urlHelpers';
import { getActionText, getStatus } from '../../util/data';

import { routeConfiguration } from '../..';
import { ReviewRating } from '../../components';

import css from './ListingPage.module.css';

const { UUID } = sdkTypes;

const SectionBiddingList = props => {
  const {
    publicData,
    biddingData,
    biddingStatus,
    isOwnListing,
    acceptBid,
    currentUser,
    transaction,
    listing,
    acceptSaleError,
    acceptInProgress,
    declineSaleError,
    declineInProgress,
  } = props;
  const userId = currentUser ? currentUser.id.uuid : '';
  if (!publicData || !userId) {
    return null;
  }
  const initialState = {
    loader: false,
    acceptingBid: null,
  };
  const [loader, setloader] = useState(initialState.loader);
  const [acceptingBid, setacceptingBid] = useState(initialState.acceptingBid);
  const handlePayment = value => {
    const {
      listingId,
      getListing,
      currentUser,
      callSetInitialValues,
      onInitializeCardPaymentData,
      history,
    } = props;
    // const listingId = new UUID(params.id);
    const listing = getListing(listingId);
    const saveToSessionStorage = !currentUser;
    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);
    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const initialValues = {
      listing,
      bookingData: value,
      bookingDates: {
        bookingStart: new Date(),
        bookingEnd: tomorrow,
      },
      confirmPaymentError: null,
    };
    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);
    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();
    let transactionId = null;
    const author_id = listing.author.id.uuid;
    const current_user_id = currentUser.id.uuid;
    if (listing.attributes.metadata.bidding) {
      let buyerId = Object.keys(listing.attributes.metadata.bidding).find(
        element =>
          listing?.attributes?.metadata?.bidding[element]?.buyerStatus === 3 &&
          listing?.attributes?.metadata?.bidding[element]?.sellerStatus === 2
      );
      if (author_id === current_user_id) {
        if (buyerId) {
          transactionId = listing.attributes.metadata.bidding[buyerId].buyer_transaction_id;
          history.push(`/sale/${transactionId}/details`);
        }
      } else {
        // Redirect to CheckoutPage
        history.push(
          createResourceLocatorString(
            'CheckoutPage',
            routes,
            { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
            {}
          )
        );
      }
    }
  };
  const author_id = listing.author.id.uuid;
  const current_user_id = currentUser.id.uuid;
  const getStyle = text => {
    let style = '';
    switch (text) {
      case 'Approved':
        style = css.approved;
        break;
      case 'Pending':
        style = css.pending;
        break;
      case 'Payment done by buyer':
        style = css.approved;
        break;
      case 'Complete':
        style = css.approved;
        break;
      case 'Payment declined':
        style = css.pending;
        break;
      case 'Payment expired':
        style = css.pending;
      case 'Listing closed':
        style = css.pending;
      default:
        break;
    }
    return style;
  };
  const acceptErrorMessage = acceptSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.acceptSaleFailed" />
    </p>
  ) : null;
  const declineErrorMessage = declineSaleError ? (
    declineSaleError.apiErrors &&
    declineSaleError.apiErrors[0] &&
    declineSaleError.apiErrors[0].meta &&
    declineSaleError.apiErrors[0].meta.stripeCode === 'charge_disputed' ? (
      <p className={css.actionError}>
        <FormattedMessage id="TransactionPanel.declineSaleFailedDisputed" />
      </p>
    ) : (
      <p className={css.actionError}>
        <FormattedMessage id="TransactionPanel.declineSaleFailed" />
      </p>
    )
  ) : null;
  const buttonsDisabled = acceptInProgress
  //  || declineInProgress;
  const highestBid =
    biddingData && Object.values(biddingData).length > 0 && Object.values(biddingData)[0];
  const removeOthersBid =
    author_id !== current_user_id
      ? biddingData &&
        Object.values(biddingData).length > 0 &&
        Object.values(biddingData).filter(bid => {
          return bid.id === current_user_id && highestBid.id !== current_user_id;
        })
      : [];
  let filteredBiddingData = [highestBid].concat(removeOthersBid);
  if (author_id === current_user_id) {
    filteredBiddingData = biddingData;
  }
  const isAnyBidAccepted =
    filteredBiddingData && filteredBiddingData.find(b => b?.buyerStatus === 3) ? true : false;
  // if(filteredBiddingData && Object.values(filteredBiddingData) && Object.values(filteredBiddingData).length > 0) {
  //   let biddingDataModified = Object.values(biddingData);
  //   biddingDataModified &&
  //     biddingDataModified.map(async bid => {
  //       const txid = new UUID(bid.buyer_transaction_id);
  //       let transactionObj = {};
  //       await props.onfetchTransaction(txid,'onlytransaction').then(resTransaction => {
  //         const transactionData = resTransaction.data.data
  //         transactionObj = {
  //           isAcceptedTransitionState :isAcceptedTransition(transactionData) ? true : false,
  //           lastTransition : txLastTransition(transactionData)
  //         }
  //       }).catch(err => null);
  //       bid.transactionData = transactionObj
  //     });
  // }
  return (
    <div className={css.sectionBiddingList}>
      <div className={css.biddingThreeToTwoWrapper}>
        <div className={css.biddingListHeader}>
          <div className={css.biddingHeadingMain}>Bids</div>
          <div className={css.biddingHeadingCount}>{`All (${
            filteredBiddingData && typeof filteredBiddingData === 'object'
              ? filteredBiddingData.length
              : 0
          })`}</div>
        </div>
        <div className={css.biddingListContainer}>
          {acceptErrorMessage ? acceptErrorMessage : null}
          {declineErrorMessage ? declineErrorMessage : null}
          {biddingData && typeof biddingData === 'object' ? (
            filteredBiddingData.map((bidObj, index) => (
              <div className={css.biddingListSection} key={index}>
                <div className={css.firstSection}>
                  <div className={css.imgNameWrapper}>
                    <div className={css.avatarBase}>
                      <span className={css.initials}>
                        {bidObj.userName ? bidObj.userName : 'AN'}
                      </span>
                    </div>
                    <div className={css.nameDateWrapper}>
                      <span className={css.bidder}>Bidder {index + 1}</span>
                      <span className={css.dateDesktop}>
                        {moment(bidObj.timeStamp).format('MMM DD, hh:mm:ss A')}
                      </span>
                    </div>
                    <div className={css.reviewsWrapper}>
                      {author_id === current_user_id && bidObj && bidObj.reviews && (
                        <>
                          <ReviewRating
                            reviewStarClassName={css.reviewStar}
                            className={css.reviewStars}
                            rating={bidObj.reviews.rating}
                          />
                          <div className={css.reviewsCount}>
                            {bidObj?.reviews ? `(${bidObj.reviews.count})` : `(0)`}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className={css.statusesWrapper}>
                  <div className={css.statusesInnerWrapper}>
                    {getStatus(bidObj, transaction, listing, isAnyBidAccepted) ? (
                      <span
                        className={classNames(
                          css.statusText,
                          getStyle(getStatus(bidObj, transaction))
                        )}
                      >
                        {acceptInProgress &&
                        declineInProgress &&
                        acceptingBid === bidObj.buyer_transaction_id ? (
                          <span className={classNames(css.loader, css.threeDots)}></span>
                        ) :getStatus(bidObj, transaction, listing, isAnyBidAccepted)}
                      </span>
                    ) : null}
                    {index === 0 ? (
                      <span className={classNames(css.topBid, css.topBidDesktop)}>Top bid</span>
                    ) : (
                      <span className={css.emptySection}></span>
                    )}
                  </div>
                  <div className={css.paymentWrapperMobile}>
                    {getActionText(
                      bidObj,
                      author_id,
                      current_user_id,
                      biddingStatus,
                      transaction,
                      listing,
                      acceptingBid
                    ) !== null &&
                    bidObj?.buyerStatus === 1 &&
                    new Date(
                      new Date(bidObj.timeStamp).setDate(new Date(bidObj.timeStamp).getDate() + 2)
                    ) >= new Date() ? (
                      <span
                        className={classNames(css.acceptBid, css.btn_main)}
                        onClick={async () => {
                          if (buttonsDisabled) {
                            return null;
                          }
                          if (
                            getActionText(
                              bidObj,
                              author_id,
                              current_user_id,
                              biddingStatus,
                              transaction,
                              listing,
                              acceptingBid
                            ) === 'Accept bid'
                          ) {
                            setloader(true);
                            setacceptingBid(bidObj.buyer_transaction_id);
                            const acceptBid_Res = await acceptBid(bidObj);
                            if (acceptBid_Res) {
                              setloader(false);
                              setacceptingBid(null);
                            }
                          } else if (
                            getActionText(
                              bidObj,
                              author_id,
                              current_user_id,
                              biddingStatus,
                              transaction,
                              listing,
                              acceptingBid
                            ) === 'Pay'
                          ) {
                            handlePayment(bidObj);
                          } else {
                            null;
                          }
                        }}
                      >
                        {acceptInProgress &&
                        // declineInProgress &&
                        acceptingBid === bidObj.buyer_transaction_id ? (
                          <span className={classNames(css.loader, css.threeDots)}></span>
                        ) : (
                          getActionText(
                            bidObj,
                            author_id,
                            current_user_id,
                            biddingStatus,
                            transaction,
                            listing,
                            acceptingBid
                          )
                        )}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className={css.endSection}>
                  {author_id === current_user_id ? (
                    <span className={css.bidPrice}>$ {Number(bidObj.bid).toLocaleString()}</span>
                  ) : null}
                  <div className={css.acceptBidCover}>
                    {getActionText(
                      bidObj,
                      author_id,
                      current_user_id,
                      biddingStatus,
                      transaction,
                      listing,
                      acceptingBid
                    ) !== null &&
                    bidObj?.buyerStatus === 1 &&
                    new Date(
                      new Date(bidObj.timeStamp).setDate(new Date(bidObj.timeStamp).getDate() + 2)
                    ) >= new Date() ? (
                      <span
                        className={classNames(css.acceptBid, css.btn_main)}
                        onClick={async () => {
                          if (buttonsDisabled) {
                            return null;
                          }
                          if (
                            getActionText(
                              bidObj,
                              author_id,
                              current_user_id,
                              biddingStatus,
                              transaction,
                              listing,
                              acceptingBid
                            ) === 'Accept bid'
                          ) {
                            setloader(true);
                            setacceptingBid(bidObj.buyer_transaction_id);
                            const acceptBid_Res = await acceptBid(bidObj);
                            if (acceptBid_Res) {
                              setloader(false);
                              setacceptingBid(null);
                            }
                          } else if (
                            getActionText(
                              bidObj,
                              author_id,
                              current_user_id,
                              biddingStatus,
                              transaction,
                              listing,
                              acceptingBid
                            ) === 'Pay'
                          ) {
                            handlePayment(bidObj);
                          } else {
                            null;
                          }
                        }}
                      >
                        {acceptInProgress &&
                        // declineInProgress &&
                        acceptingBid === bidObj.buyer_transaction_id ? (
                          <span className={classNames(css.loader, css.threeDots)}></span>
                        ) : (
                          getActionText(
                            bidObj,
                            author_id,
                            current_user_id,
                            biddingStatus,
                            transaction,
                            listing,
                            acceptingBid
                          )
                        )}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div> No Bids Found.</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SectionBiddingList;
