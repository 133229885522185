import React, { useState, useEffect } from 'react';
import { bool, func, shape, string, oneOf } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import config from '../../config';
import routeConfiguration from '../../routeConfiguration';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { LISTING_STATE_PENDING_APPROVAL, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import NotificationsHandlers from '../../util/notifications';
import { formatMoney } from '../../util/currency';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  getMetadata,
  isMetadata,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { pathByRouteName } from '../../util/routes';

import {
  Page,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { TopbarContainer, NotFoundPage } from '..';
import SectionImages from './SectionImages';
import SectionBiddingList from './SectionBiddingList';
import SectionValuationList from './SectionValuationList';

import { fetchReviews, requestUpdateListing } from './ListingPage.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { capturePaymentIntentAction, initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import { acceptSale, fetchTransaction } from '../TransactionPage/TransactionPage.duck';
import { confirmPayment } from '../CheckoutPage/CheckoutPage.duck';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const sortBidData = (data = {}) => {
  return Object.values(data).sort((objA, objB) => (Number(objA.valuation) > Number(objB.valuation) ? -1 : 1));
};

export const CommonListPageComponent = props => {
  const {
    currentUser,
    isAuthenticated,
    getListing,
    getOwnListing,
    intl,
    onManageDisableScrolling,
    params: rawParams,
    location,
    updateInProgress,
    scrollingDisabled,
    showListingError,
    updateListing,
    speculatedTransaction,
    match,
    acceptSaleError,
    acceptInProgress,
    declineSaleError,
    declineInProgress,
  } = props;
  const notificationsHandlers = new NotificationsHandlers();
  const listingId = new UUID(rawParams.id);
  const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const currentListing =
    isPendingApprovalVariant || isDraftVariant
      ? ensureOwnListing(getOwnListing(listingId))
      : ensureListing(getListing(listingId));

  const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
  const listType = rawParams.listType;

  const params = { slug: listingSlug, ...rawParams };

  const listingType = isDraftVariant ? LISTING_PAGE_PARAM_TYPE_DRAFT : LISTING_PAGE_PARAM_TYPE_EDIT;
  const listingTab = isDraftVariant ? 'photos' : 'description';

  const isApproved =
    currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

  const pendingIsApproved = isPendingApprovalVariant && isApproved;

  // If a /pending-approval URL is shared, the UI requires
  // authentication and attempts to fetch the listing from own
  // listings. This will fail with 403 Forbidden if the author is
  // another user. We use this information to try to fetch the
  // public listing.
  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) &&
    showListingError &&
    showListingError.status === 403;
  const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

  if (shouldShowPublicListingPage) {
    return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
  }

  const {
    description = '   ',
    geolocation = null,
    price = null,
    title = '',
    publicData,
    metadata,
  } = currentListing.attributes;

  const [biddingData, setBiddingData] = useState(null);
  const [valuationData, setValuationData] = useState(null);
  const biddingStatus = metadata ? metadata.biddingStatus : null;

  useEffect(() => {
    const { onFetchReviews, onfetchTransaction } = props;
    if (metadata && getListing && params) {
      const listingId = new UUID(props.params.id);
      const currentListing = ensureListing(props.getListing(listingId));
      const { metadata } = currentListing.attributes;
      if (metadata.bidding) {
        const biddingData =
          Object.values(metadata.bidding).length > 0 ? sortBidData(metadata.bidding) : null;
        let biddingDataModified = biddingData;
        biddingDataModified &&
          biddingDataModified.map(async bid => {
            const fetchReviewsRes = await onFetchReviews(bid.id);
            if (fetchReviewsRes) {
              const reviewsCount = fetchReviewsRes ? fetchReviewsRes.length : 0;
              const ratingArray =
                (await fetchReviewsRes) && fetchReviewsRes.map(review => review.attributes.rating);
              const arraySum = await ratingArray.reduce((a, b) => a + b, 0);
              bid.reviews = {
                count: reviewsCount,
                rating: Math.round(arraySum / reviewsCount),
              };
            }
          });
        setBiddingData(biddingDataModified);
      }
      if (metadata.valuation) {
        const valuationData =
          Object.values(metadata.valuation).length > 0 ? sortBidData(metadata.valuation) : null;
        setValuationData(valuationData);
      }
    }
  }, [metadata]);

  const richTitle = (
    <span>
      {richText(title, {
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
        longWordClass: css.longWord,
      })}
    </span>
  );

  const topbar = <TopbarContainer />;

  if (showListingError && showListingError.status === 404) {
    // 404 listing not found

    return <NotFoundPage />;
  } else if (showListingError) {
    // Other error in fetching listing

    const errorTitle = intl.formatMessage({
      id: 'ListingPage.errorLoadingListingTitle',
    });

    return (
      <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <p className={css.errorText}>
              <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
            </p>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  } else if (!currentListing.id) {
    // Still loading the listing

    const loadingTitle = intl.formatMessage({
      id: 'ListingPage.loadingListingTitle',
    });

    return (
      <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <p className={css.loadingText}>
              <FormattedMessage id="ListingPage.loadingListingMessage" />
            </p>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  } else if (!listType || (!(listType === 'bids') && !(listType === 'valuations'))) {
    return <NotFoundPage />;
  }

  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const lmctUnverified =
    !!currentUser &&
    !!currentUser.id &&
    !!currentUser.attributes.profile &&
    !!currentUser.attributes.profile.publicData &&
    !currentUser.attributes.profile.publicData.lmctVerified;
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);

  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

  const { formattedPrice, priceTitle } = priceData(price, intl);

  const listingImages = (listing, variantName) =>
    (listing.images || [])
      .map(image => {
        const variants = image.attributes.variants;
        const variant = variants ? variants[variantName] : null;

        // deprecated
        // for backwards combatility only
        const sizes = image.attributes.sizes;
        const size = sizes ? sizes.find(i => i.name === variantName) : null;

        return variant || size;
      })
      .filter(variant => variant != null);

  const facebookImages = listingImages(currentListing, 'facebook');
  const twitterImages = listingImages(currentListing, 'twitter');
  const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage(
    { id: 'ListingPage.schemaTitle' },
    { title, price: formattedPrice, siteTitle }
  );

  const acceptBid = async data => {
    const { currentUser, onAcceptSale, onfetchTransaction, onConfirmPayment, onCapturePaymentIntentAction } = props;
    if (!data || !data.id) return;
    const buyerStatus = 2,
      sellerStatus = 2,
      userId = data.id;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);
    // const onAcceptSale_Res = await onAcceptSale({
    //   id: new UUID(data.buyer_transaction_id),
    //   listing: { ...listing, buyerId: userId },
    // });
    if (
      isMetadata(listing) &&
      getMetadata(listing) &&
      getMetadata(listing)[userId] &&
      getMetadata(listing)[userId].buyer_transaction_id
    ) {
      const existing_buyer_transaction_id = getMetadata(listing)[userId].buyer_transaction_id;
      const txId = new UUID(existing_buyer_transaction_id);
      let currentTransaction = null;
      // step fetch transaction
      const fnFetchTransaction = fnParams => {
        // console.log('fnFetchTransaction', fnParams);
        return onfetchTransaction(txId, 'onlytransaction');
      };

      const fnOnConfirmPayment = fnParams => {
        // console.log('fnOnConfirmPayment', fnParams);
        currentTransaction = fnParams.data.data;
        const hasPaymentIntents = currentTransaction?.attributes?.metadata?.paymentIntent;
        if (!hasPaymentIntents) {
          throw new Error(
            `Missing StripePaymentIntents key in transaction's protectedData. Check that your transaction process is configured to use payment intents.`
          );
        }
        return onConfirmPayment({
          listing: listing,
          type: 'bid',
          transactionId: currentTransaction.id,
          payment_method: currentTransaction?.attributes?.metadata?.paymentIntent?.payment_method,
        });
      };

      const fnOnCapturePaymentIntentAction = fnParams => {
        // console.log('fnOnCapturePaymentIntentAction', fnParams);
        const stripePaymentIntentId =
          currentTransaction?.attributes?.metadata?.paymentIntent?.stripePaymentIntentId;
        const payment_method =
          currentTransaction?.attributes?.metadata?.paymentIntent?.payment_method;
        return onCapturePaymentIntentAction({ id: stripePaymentIntentId, payment_method });
      };

      const fnOnAcceptSale = fnParams => {
        // console.log('fnOnAcceptSale', fnParams);
        return onAcceptSale({
          id: currentTransaction.id,
          listing: { ...listing, buyerId: currentTransaction?.relationships?.customer?.data?.id?.uuid },
        });
      };

      const applyAsync = (acc, val) => acc.then(val);
      const composeAsync = (...funcs) => x => funcs.reduce(applyAsync, Promise.resolve(x));
      const handlePaymentIntentUpdation = composeAsync(
        fnFetchTransaction,
        fnOnConfirmPayment,
        fnOnCapturePaymentIntentAction,
        fnOnAcceptSale
      );
      handlePaymentIntentUpdation({ currentTransaction, listing })
        .then(res => {
          // console.log('res', res);
          // if (res.status === 200) {
          //   // return callback();
          // }
        })
        .catch(err => {
          console.log('err', err);
          const error = true;
          // return callback(error);
        });
    } else {
      // console.log('no bid present');
    }
    // return onAcceptSale_Res;
  };

  const convertListing = (data, callback) => {
    if (
      !data.price ||
      // || !data.instantPrice
      !data.listingType ||
      !params.id
    ) {
      return false;
    }
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);
    const authorId = listing.author ? listing.author.id.uuid : '';
    updateListing(
      {
        price: data.price,
        publicData: {
          listingType: data.listingType,
          instantPrice: data?.instantPrice?.amount ? data.instantPrice.amount : null,
        },
        metadata: {
          valuation: null,
        },
        id: listing.id,
      },
      () => {
        if (props.history) {
          const listingPagePath = pathByRouteName('ListingPage', routeConfiguration(), {
            id: params.id,
            slug: listingSlug,
            userid: params.userid ? params.userid : 'u',
          });
          callback();
          props.history.push(listingPagePath);
        }
      },
      authorId
    );
  };
  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      author={authorDisplayName}
      contentType="website"
      description={description}
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'ItemPage',
        description: description,
        name: schemaTitle,
        image: schemaImages,
      }}
    >
      <LayoutSingleColumn className={css.pageRoot}>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div>
            <SectionImages
              title={title}
              listing={currentListing}
              isOwnListing={isOwnListing}
              history={props.history}
              editParams={{
                id: listingId.uuid,
                slug: listingSlug,
                type: listingType,
                tab: listingTab,
              }}
              showCarousel={false}
              backTo="ListingPage"
              onManageDisableScrolling={onManageDisableScrolling}
            />
            <div className={css.biddingSectionHeading}>
              <div className={css.biddingThreeToTwoWrapper}>
                <div className={css.maxWidthWrapper}>
                  <div className={css.headerContainer}>
                    <div className={css.biddingHeading}>
                      <h1 className={css.biddingTitle}>
                        {' '}
                        {publicData && publicData.modelYear ? (
                          <span>{`${publicData.modelYear} `}</span>
                        ) : null}
                        {richTitle}
                      </h1>
                      {publicData && publicData.kilometers ? (
                        <div className={css.biddingSecondaryData}>
                          {publicData.kilometers.toLocaleString()} kms
                        </div>
                      ) : null}
                      {publicData && publicData.registration ? (
                        <div className={css.biddingSecondaryData}>
                          Registration number {publicData.registration}
                        </div>
                      ) : null}
                    </div>
                    {isAuthenticated && !lmctUnverified && formattedPrice && priceTitle ? (
                      <div className={css.biddingDesktopPriceContainer}>
                        <div className={css.biddingDesktopPriceValue} title={priceTitle}>
                          {formattedPrice}
                        </div>
                        <div className={css.biddingDesktopPerUnit}>
                          <FormattedMessage id="ListingPage.priceGuide" />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            {listType === 'bids' ? (
              <SectionBiddingList
                publicData={publicData}
                biddingData={biddingData}
                biddingStatus={biddingStatus}
                isOwnListing={isOwnListing}
                acceptBid={acceptBid}
                //for transaction
                listingId={listingId}
                listing={currentListing}
                speculatedTransaction={speculatedTransaction}
                getListing={props.getListing}
                currentUser={props.currentUser}
                callSetInitialValues={props.callSetInitialValues}
                onInitializeCardPaymentData={props.onInitializeCardPaymentData}
                history={props.history}
                transaction={props.transaction}
                acceptSaleError={acceptSaleError}
                acceptInProgress={acceptInProgress}
                onfetchTransaction={props.onfetchTransaction}
                declineSaleError={declineSaleError}
                declineInProgress={declineInProgress}
                //for transaction
              />
            ) : listType === 'valuations' ? (
              <SectionValuationList
                publicData={publicData}
                valuationData={valuationData}
                convertListing={convertListing}
                isOwnListing={isOwnListing}
                onManageDisableScrolling={onManageDisableScrolling}
                currentUser={props.currentUser}
                path={match.path}
              />
            ) : null}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

CommonListPageComponent.defaultProps = {
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
};

CommonListPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  updateListing: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  onAcceptSale: func.isRequired,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
};

const mapStateToProps = state => {
  const { showListingError, enquiryModalOpenForListingId, updateInProgress } = state.ListingPage;
  const { isAuthenticated } = state.Auth;
  const { currentUser } = state.user;
  const {
    transactionRef,
    acceptInProgress,
    acceptSaleError,
    declineSaleError,
    declineInProgress,
  } = state.TransactionPage;
  const transactions = getMarketplaceEntities(state, transactionRef ? [transactionRef] : []);
  const transaction = transactions.length > 0 ? transactions[0] : null;
  const { speculatedTransaction } = state.CheckoutPage;
  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };
  return {
    currentUser,
    isAuthenticated,
    updateInProgress,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    speculatedTransaction,
    transaction,
    acceptSaleError,
    acceptInProgress,
    declineSaleError,
    declineInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onAcceptSale: ({ id, listing }) => dispatch(acceptSale({ id, listing })),
  updateListing: (values, callback, authorId, transitionType, transaction) =>
    dispatch(requestUpdateListing(values, callback, authorId, transitionType, transaction)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchReviews: userId => dispatch(fetchReviews(userId)),
  onfetchTransaction: (id, txRole) => dispatch(fetchTransaction(id, txRole)),
  onConfirmPayment: params => dispatch(confirmPayment(params)),
  onCapturePaymentIntentAction: (params) => dispatch(capturePaymentIntentAction(params)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const CommonListPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(CommonListPageComponent);

export default CommonListPage;
