import React from 'react';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

import { FormattedMessage } from '../../util/reactIntl';
import { ensureListing } from '../../util/data';
import { LISTING_STATE_DRAFT, LISTING_STATE_CLOSED } from '../../util/types';

import { ResponsiveImage, Modal, ImageCarousel, NamedLink } from '../../components';
import ActionBarMaybe from './ActionBarMaybe';

import css from './ListingPage.module.css';

const SectionImages = props => {
  const {
    title,
    listing,
    isOwnListing,
    editParams,
    handleViewPhotosClick,
    imageCarouselOpen,
    onImageCarouselClose,
    onManageDisableScrolling,
    showCarousel,
    history,
    backTo,
  } = props;

  const currentListing = ensureListing(listing);
  const state = listing.attributes.state;
  const isDraft = state === LISTING_STATE_DRAFT;
  const isClosed = state === LISTING_STATE_CLOSED;
  const { publicData } = currentListing.attributes;
  const sortImageArr = (images = [], ids = []) => {
    let imgArr = [];
    if (images && Array.isArray(images) && images.length > 0) {
      imgArr = images.sort((a, b) => (a.id && a.id.uuid && ids.includes(a.id.uuid) ? -1 : 1));
    }
    return imgArr;
  };

  const listingImageIds =
    publicData && publicData.listingImageIds ? publicData.listingImageIds : null;
  // console.log('listingImageIds', listingImageIds);
  let imagesMain =
    listing.images && listing.images.length > 0
      ? sortImageArr(listing.images, listingImageIds)
      : null;
  let newData = [];
  const imagesData = listing.images && listing.images.length > 0 ? listing.images : null;
  listingImageIds && listingImageIds.map(listingImageId => {
    imagesData && imagesData.map(image => {
      if (listingImageId === image.id.uuid) {
        newData.push(image);
      }
    });
  });
  // console.log('newData', newData);
  const filterArray = (arr1, arr2) => {
    const filtered = arr1.filter(el => {
      return arr2.indexOf(el.id.uuid) === -1;
    });
    return filtered;
  };
  const filterArrayData = imagesData && listingImageIds ? filterArray(imagesData, listingImageIds) : null
  // console.log('filterArrayData', filterArrayData);
  imagesMain = newData.concat(filterArrayData);
  const hasImages = imagesMain && imagesMain.length > 0;
  const firstImage = hasImages ? imagesMain[0] : null;
  // console.log('firstImage', firstImage);

  // Action bar is wrapped with a div that prevents the click events
  // to the parent that would otherwise open the image carousel
  const actionBar = listing.id ? (
    <div onClick={e => e.stopPropagation()}>
      <ActionBarMaybe isOwnListing={isOwnListing} listing={listing} editParams={editParams} />
    </div>
  ) : null;

  const viewPhotosButton =
    hasImages && showCarousel ? (
      <button className={css.viewPhotos} onClick={handleViewPhotosClick}>
        <FormattedMessage
          id="ListingPage.viewImagesButton"
          values={{ count: listing.images.length }}
        />
      </button>
    ) : null;

  return (
    <div className={css.sectionImages}>
      <div className={css.threeToTwoWrapper}>
        {/* <NamedLink
          className={css.backBtn}
          name={backTo ? backTo : 'LandingPage'}
          params={{ id: editParams.id, slug: editParams.slug }}
        >
          <button className={css.backBtn}>
            {' '}
            <NavigateBeforeIcon className={css.backIcon} />
            {backTo && backTo === 'LandingPage'
              ? ' Back'
              : ' Back to my listings'}
          </button>
        </NamedLink> */}
        <button
          className={css.backBtn}
          onClick={() => {
            if (history && history.action !== 'REPLACE') {
              history.goBack();
            } else {
              history.push('/');
            }
          }}
        >
          {' '}
          <NavigateBeforeIcon className={css.backIcon} />
          {backTo && backTo === 'LandingPage' ? ' Back' : ' Back to my listing'}
        </button>
        <div className={css.aspectWrapper} onClick={handleViewPhotosClick}>
          <div className={css.imageWrapper}>
            {/* {actionBar} */}
            <ResponsiveImage
              rootClassName={css.rootForImage}
              alt={title}
              image={firstImage}
              variants={[
                'landscape-crop',
                'landscape-crop2x',
                'landscape-crop4x',
                'landscape-crop6x',
              ]}
            />

            {/* <EditIcon className={css.editIcon} /> */}
            <div>
              {isOwnListing ? (
                !isClosed ? (
                  <NamedLink className={css.editListBtn} name="EditListingPage" params={editParams}>
                    {isDraft ? 'Finish listing' : 'Edit Listing'}
                  </NamedLink>
                ) : (
                  <div className={css.actionBar}>
                    <p className={css.closedListingText}>
                      <FormattedMessage id="ListingPage.ownClosedListing" />
                    </p>
                  </div>
                )
              ) : isClosed ? (
                <div className={css.actionBar}>
                  <p className={css.closedListingText}>
                    <FormattedMessage id="ListingPage.closedListing" />
                  </p>
                </div>
              ) : null}

              {viewPhotosButton}
            </div>
          </div>
        </div>
      </div>
      {showCarousel ? (
        <Modal
          id="ListingPage.imageCarousel"
          scrollLayerClassName={css.carouselModalScrollLayer}
          containerClassName={css.carouselModalContainer}
          lightCloseButton
          isOpen={imageCarouselOpen}
          onClose={onImageCarouselClose}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <ImageCarousel images={imagesMain} />
        </Modal>
      ) : null}
    </div>
  );
};

export default SectionImages;
