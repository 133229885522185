import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';

import config from '../../config';

import { intlShape, injectIntl } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import { findOptionsForSelectFilter } from '../../util/search';
import {
  unitDivisor,
  convertUnitToSubUnit,
  ensureDotSeparator,
} from '../../util/currency';

import { Modal } from '../../components';
import { ConvertListingForm } from '../../forms';

import css from './ListingPage.module.css';

const { Money } = sdkTypes;

// Convert unformatted value (e.g. 10,00) to Money (or null)
const getPrice = (unformattedValue, currencyConfig) => {
  const isEmptyString = unformattedValue === '';
  try {
    return isEmptyString
      ? null
      : new Money(
          convertUnitToSubUnit(
            unformattedValue,
            unitDivisor(currencyConfig.currency)
          ),
          currencyConfig.currency
        );
  } catch (e) {
    return null;
  }
};

const SectionValuationListComponent = props => {
  const {
    publicData,
    valuationData,
    currentUser,
    convertListing,
    isOwnListing,
    onManageDisableScrolling,
    intl,
    path
  } = props;
  const userId = currentUser ? currentUser.id.uuid : '';
  const [isValuationModalOpen, setValuationModal] = useState(false);
  const [valuationSelectedData, setValuationSelectedData] = useState('');
  const [selectedValues, setSelectedValues] = useState({ listingType: null });

  if (!publicData || !userId) {
    return null;
  }
  const handleValuationSelect = (data = '') => {
    if (data) {
      setValuationSelectedData(data);
    }
  };

  const onOpenValuationModal = () => {
    setValuationModal(true);
  };
  const onCloseValuationModal = () => {
    setValuationModal(false);
    setValuationSelectedData('');
  };

  const handleFormSubmit = values => {
    values.listingType = values.listingType === "Auction/Buy Now" ? 'bid' : null
    convertListing(values, onCloseValuationModal);
  };

  const formatPrice = price => {
    if (typeof price === 'string') {
      return getPrice(ensureDotSeparator(price), config.currencyConfig);
    }
    return price;
  };

  const onFormChange = value => {
    if (
      value.active &&
      value.values[value.active] &&
      value.values[value.active] != selectedValues[value.active]
    ) {
      setSelectedValues({
        ...selectedValues,
        [value.active]: value.values[value.active],
      });
    }
  };

  const listingTypeOptions = findOptionsForSelectFilter(
    'listingType',
    config.custom.filters
  ).filter(obj => obj.key != 'valuation');

  useEffect(() => {
    if (valuationSelectedData) {
      onOpenValuationModal();
    }
  }, [valuationSelectedData]);
  return (
    <div className={css.sectionValuationList}>
      <div className={css.ValuationThreeToTwoWrapper}>
        <div className={css.valuationListHeader}>
          <div className={css.valuationHeadingMain}>Valuations</div>
          <div className={css.valuationHeadingCount}>{`All (${
            valuationData && typeof valuationData === 'object'
              ? Object.values(valuationData).length
              : 0
          })`}</div>
        </div>
        <div className={css.valuationListContainer}>
          {valuationData && typeof valuationData === 'object' ? (
            Object.values(valuationData).map((valuationObj, index) => (
              <div className={css.valuationListSection} key={index}>
                <div className={css.firstSectionValuation}>
                  <div className={css.imgNameWrapperValuation}>
                    <div className={css.avatarBaseValuation}>
                      <span className={css.initialsValuation}>
                        {valuationObj.userName ? valuationObj.userName : 'AN'}
                      </span>
                    </div>
                    <span className={css.bidderValuation}>
                      Valuator {index + 1}
                    </span>
                  </div>
                  <span className={css.dateDesktopValuation}>
                    {moment(valuationObj.timeStamp).format('MMM DD, hh:mm A')}
                  </span>
                </div>
                <div className={css.locationWrapperValuation}>
                  {/* <span className={css.dateMobileValuation}>
                    {moment(valuationObj.timeStamp).format('MMM DD, hh:mm A')}
                  </span> */}
                  {valuationObj.location ? (
                    <span className={css.locValuation}>Location</span>
                  ) : null}
                </div>
                <div className={css.endSectionValuation}>
                  <span className={css.valuationPrice}>
                    $ {Number(valuationObj.valuation).toLocaleString()}
                  </span>
                  <div className={css.acceptBidCover}>
                  {isOwnListing ? (
                    <span
                      className={css.acceptBid}
                      onClick={() => handleValuationSelect(valuationObj)}
                    >
                      Select Valuation
                    </span>
                  ) : null}
                  </div>
                </div>
                {isOwnListing ? (
                  <Modal
                    id="ValuationPage"
                    isOpen={isValuationModalOpen}
                    onClose={onCloseValuationModal}
                    usePortal
                    onManageDisableScrolling={onManageDisableScrolling}
                  >
                    <div className={css.modalContainer}>
                      <p className={css.modalTitle}>Convert Listing</p>
                      <ConvertListingForm
                        id="ValuationPageForm"
                        initialValues={{
                          price:
                            valuationSelectedData &&
                            valuationSelectedData.valuation
                              ? formatPrice(valuationSelectedData.valuation)
                              : null,
                          instantPrice: selectedValues.instantPrice
                            ? selectedValues.instantPrice
                            : null,
                          listingType: listingTypeOptions
                            ? listingTypeOptions[0].label
                            : null,
                        }}
                        intl={intl}
                        onFormChange={onFormChange}
                        onSubmit={handleFormSubmit}
                        listingTypeOptions={listingTypeOptions}
                        path={path}
                      />
                    </div>
                  </Modal>
                ) : null}
              </div>
            ))
          ) : (
            <div> No Valuations Found.</div>
          )}
        </div>
      </div>
    </div>
  );
};

SectionValuationListComponent.defaultProps = {};

SectionValuationListComponent.propTypes = {
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => ({});

const SectionValuationList = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(SectionValuationListComponent);

export default SectionValuationList;
